import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

export default ({data}) => (
  <>
    <SEO title={`About`} />
    <h2>About {data.site.siteMetadata.title}</h2>
    <p>Forget the fat lady! You're obsessed with the fat lady! Drive us out of here! So you two dig up, dig up dinosaurs? Eventually, you do plan to have dinosaurs on your dinosaur tour, right? Forget the fat lady! You're obsessed with the fat lady! Drive us out of here!</p>
  </>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`